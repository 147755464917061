import type { DisplayOptions } from '@/types/display'
import { DayOfWeek } from '@mantine/dates'
import { MS_PER_DAY } from '@planda/design-system'
import { startOfWeek } from 'date-fns'
import { Task, Task as TaskType } from 'src/types'

type TimePeriodOptions = DisplayOptions & { weekStartsOn?: number }

export const isPast = (x: TaskType, i: number, arr: TaskType[], options?: TimePeriodOptions) => {
    const { weekStartsOn = 0, hideCompleted } = options || {}

    if (!x.completed) return false
    if (hideCompleted) return true

    // (!displayOptions.hideCompleted || !x.completed) &&

    const weekStart = startOfWeek(Date.now(), {
        weekStartsOn: weekStartsOn && ((weekStartsOn % 7) as DayOfWeek),
    })
    return x.completed < weekStart.getTime()
    // x.dateStart ? x.dateStart < weekStart.getTime() :
    // return x.dateStart && x.completed && (x.dateStart < (Date.now() - MS_PER_DAY * 5))
}
// starting on startOfWeek
export const isFuture = (x: TaskType, i: number, arr: TaskType[], options?: TimePeriodOptions) => {
    if (isPast(x, i, arr, options)) return false
    if (x.dateHandedOut) {
        return x.dateHandedOut > Date.now()
    }

    // TODO: below is for sequential tasks, now groups aren't sequential
    // if (x.category.split('/').length >= 3) {
    //     if (!x.dateStart) return true
    //     const sameGroup = arr.filter((y, j, arr2) => !y.completed && y.category === x.category && !isPast(y, j, arr2, options))
    //     if (sameGroup.some(x => !x.dateStart)) {
    //         return sameGroup.some((y) => y.name < x.name)
    //     }
    //     // const uniqDates = uniqBy(sameGroup, (y) => y.dateStart)
    //     // if (uniqDates.length === 1) return sameGroup.some((y) => y.name < x.name)
    //     return sameGroup.some((y, j, arr2) => x.id !== y.id && y.dateStart! < x.dateStart!)
    // }
    if (!x.dateStart) return false
    if (x.dateStart > Date.now() + MS_PER_DAY * 25) return true
    return false
}

/**
 * Return task progress between 0-100
 * @param item
 * @returns
 */
export function taskProgress(item: TaskType) {
    if (item.completed) return 100
    return item.subtasks && item.subtasks.length > 0
        ? (item.subtasks.reduce((previousValue, currentValue) => previousValue + (currentValue.completed ? 1 : 0), 0) * 100) / item.subtasks.length
        : 0
}

export const orderTasks = (a: Task, b: Task, defaultSort?: (a: Task, b: Task) => number) => {
    if (a.order === b.order) {
        return defaultSort ? defaultSort(a, b) : b.createdAt - a.createdAt
    } else if (a.order === undefined) {
        return 1
    } else if (b.order === undefined) {
        return -1
    } else {
        return a.order - b.order
    }
}
